.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#connection-status{
      color: red;
      font-size: 30px;
       padding: 10px;
 }

h1.column-title {
      margin: 10px 0 0 0;
      border: 1px solid gray;
      padding-left: 12px;
      background-color: darkgray;
      color: black;
      color: #3b3b3b;
}
h2 {
      color: #6e6e6e;
      font-size: 18px;
      margin: 10px 0 10px;
}

#station-columns {
      display: flex;
      flex-direction: row;
}

#frame-columns{
      display:flex;
      width: 100%;
      background-color: #cacaca;
}

.frame-column {
      display: flex;
      flex-direction: column;
      min-width: 430px;
      background-color: #cacaca;
      border: 1px solid #b3b3b3;
}
.frame {
      display: flex;
      flex-direction: row;
      margin: 5px 10px 0px 10px;
      border: 1px solid gray;
      background-color: white; 
}
.state-started {
      /*  border: 10px  solid #c45cf8 !important; */
      background-color: #d7ffd7 !important; 
      box-sizing: border-box;
}
.state-completed{
/*      border: 10px  solid #1eeed3 !important; */
      background-color: black !important; 
      box-sizing: border-box;
      color: white;
}
.state-added-from-other-station{
     
      box-sizing: border-box;
   /*  color: white;  */
}
.state-start-canceled {
   /*   background-color: rgb(132, 211, 240) !important; */
      box-sizing: border-box;
   /*     color: yellow;   */
}

.work-state.started {
    font-weight: bolder;
    color: darkgreen;
    font-size: larger;
    font-style: oblique;
}
.work-state.completed {
      font-weight: bolder;
      color: red;
      font-size: larger;
      font-style: oblique;
  }
  .work-state.voided {
      font-weight: bolder;
      color: red;
      font-size: larger;
      font-style: oblique;
  }  

  .work-state.unvoided {
      font-weight: bolder;
      color: rgb(17, 0, 255);
      font-size: larger;
      font-style: oblique;
  }  
  .work-state.rejected {
      font-weight: bolder;
      color: rgb(255, 0, 238);
      font-size: larger;
      font-style: oblique;
  } 

.frame-year-border{
      background-color: red;
      width: 20px;
      margin-right: 5px;
}

.frame-image {
      margin: 10px;
      background-color: purple;
      height: fit-content;
}
.frame-image .frame-style-color img {
     
      height: 80px;
      padding: 10px 10px 10px 30px
      
}
.frame-details{
      display:flex;
      flex-direction: column;
      margin-left: 10px;
      padding-top: 10px;
 }

 .frame-details .top-row {
      display: flex;
      flex-direction: row;
}

.frame-details .top-row .age{
      color: gray;
      width: 80px;
      margin-left: 20px;
}

.frame-details .top-row .station-time{
      color: green;
      width: 80px;
      margin-left: 20px;
}

.frame-details .other-properties {
      color: green
}
.serial{
      font-size: 20px;
}

.size-32 {
      background-color: orange;
  }

.size-43 {
      background-color: #f579c9;
  }

.size-50 {
      background-color: #f5f568;
  }

.size-55 {
      background-color: #661a97;
  }

.size-65 {
      background-color: #5b8fdd;
  }

.size-75 {
      background-color: #526b7c;
  }
.size-85 {
      background-color: #196239;
  }

  .priority-high{
      background-color: red;
      color: white;
  }

  .priority-low{
      background-color: blue;
      color: white;
  }
  .priority-fba{
      background-color: #F89000;
      color: black;
  }
  .priority-none{
/*      background-color: white;*/
  }

  .batch{
    background: white;
     margin: 5px;
     border: 1px solid black;
  }
  .batch-image {
      display: flex;
  }

  .batch-image img {
      width: 100px;
  }

  .batch-image .frame-type {
      font-size: 25px;
      font-weight: bolder;
      color: brown;
  }

  .batch-items {
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      align-items: flex-start;
      font-size: 25px;
      font-weight: bold;
      background-color: #e8e8e8;
  }
  .batch-item {
      color: white;
  
  }

  .batch-item {
      color: white;

  }
  .year2020 {
     background-color: red;
     float: left;
  }

  .year2021 {
      background-color: green;
      float: left;
  }

  .batch-items .batch-item{

      padding: 2px;
      margin: 3px 4px 2px 5px;
      box-sizing: border-box;
      border: 1px solid gray;
      width: calc(100% - 10px);
  }

.floating-search-bar{
      display: flex;
      position: fixed;
      bottom: 0;
      left: 5px;
      align-items: center;
     /* transform: translate(-50%, -50%);*/
      background-color:#F1F1F1;
      padding: 6px; 
      z-index: 99;
}

.floating-search-bar button {
background-color: green
}

.floating-search-bar img {
      height: 45px;
 }

 .floating-search-bar input {
      height: 40px;
      font-size: 2em;  
 }

 .station-user {
      border: 1px solid lightgray;
      font-size: 20px;
      padding: 4px;
      text-align: left;
      color: white;
      font-weight: 900;
 }
 .station-user .user-name {
      padding-left: 30px;
 }
 .station-user.active {
      background-color: #2da95d;
 }

 .station-user.paused {
      background-color: #7e7e7e;
      color: #ffc5c5;
 }
 .connection-indicator{
      display: flex;
      position: fixed;
      bottom: 5px; 
      right: 0;
      /*  left: 1vw;*/
      color: #625656;
      display: flex;
      flex-direction: column;
      background-color: #ffffff4a;
      text-align: center;
      align-items: center;
 }

 .connection-indicator img {
      width: 35px;
      padding: 5px;
 }

 .connect-toggle-btn {
      display: none;
      position: fixed;
      top: 5px;
      left: 275px;
      color: white;
      width: 100px;
      height: 25px;
      background-color: #c6c6c6;
 }

 .station-totals {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      font-size: 50px;
  }

  .station-total-entry {
      display: flex;
      flex-direction: row;
      padding: 4px;
  }

 .station-total-label {
      margin-right: 10px;
      font-size: 24px;
      display: flex;
      align-items: center;
 }


.station-total.zero-total {
      border: 2px solid lime;
      background-color: white;
      color: #5f5c5c;
      padding: 0px 10px 0px 10px;
      font-weight: bolder;
}

.station-total.gtr-thn-zero-total {
      border: 2px solid lime;
      background-color: lime;
      color: #5f5c5c;
      padding: 0px 10px 0px 10px;
      font-weight: bolder;
}

.station-total.red.zero-total {
      border: 2px solid red;
      background-color: white;
      color: #5f5c5c;
      padding: 0px 10px 0px 10px;
      font-weight: bolder;
}

.station-total.red.gtr-thn-zero-total {
      border: 2px solid red;
      background-color: red;
      color: white;
      padding: 0px 10px 0px 10px;
      font-weight: bolder;
}

.ticket-total {
      font-size: 50px;
      border: 2px solid lime;
      background-color: white;
      color: #5f5c5c;
      padding: 0px 10px 0px 10px;
      font-weight: bolder;
}


 .circle {
      border: 1px solid lime;
      border-radius: 20px;
      background-color: white;
      color: #5f5c5c;
      padding: 0px 10px 0px 10px;
      font-weight: bolder;
    }
    .circle.white {
      border: 1px solid red !important;
      background-color: white !important;
    }


    .column-title {
      font-size: 32px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 10px;
    align-items: self-end;
    border: 1px solid gray;
    background-color: darkgray;
  }

  .column-count {
      margin-left: 10px;
      font-size: 25px;
      color: gray;
  }

.todays-tally-wrapper{
      display: flex;
      position: fixed;
      flex-direction: column;
      right: 15px;
      bottom: 15px;
      font-size: 138px;
      background-color: white;
      font-weight: 300;
      padding: 20px;
      border: 2px solid lightgray;
      box-shadow: 5px 5px 10px 0px #cacaca;
}
.todays-tally-wrapper .title {
      display: flex;
      font-weight: 600;
      background-color: #efeded;
      padding: 5px;
      color: darkslategray;
      margin-bottom: 10px;
}

.todays-tally-wrapper .dept {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-weight: 200;
}

.todays-tally-wrapper .dept.grand-total{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
}

.todays-tally-wrapper .dept.grand-total .fips{
      color: darkslategray;
      font-size: 5rem;
      font-weight: bold;
}

.todays-tally-wrapper :nth-child(3)  {
      padding-bottom: 10px;
      border-bottom: 1px solid lightgray;
}

.todays-tally-wrapper :nth-child(4)  {
      padding-top: 10px;
      padding-bottom: 10px;
}

.todays-tally-wrapper :nth-child(5)  {
      display: flex;
      justify-content: flex-end;
      padding-top: 10px;
      border-top: 10px solid darkslategrey;
      color: darkslategrey;
}

.todays-tally-wrapper .total {
      margin-left: 10px;
}
.todays-tally-wrapper .total.zero-total {
      border: 5px solid lime;
      background-color: white;
      color: #5f5c5c;
      padding: 0px 10px 0px 10px;
      font-weight: bolder;
}

.todays-tally-wrapper .total.gtr-thn-zero-total {
      border: 5px solid lime;
      background-color: lime;
      color: #5f5c5c;
      padding: 0px 10px 0px 10px;
      font-weight: bolder;
      border-radius: 5px;
}

.boxing-prep-wrapper {
    display: flex;
    background-color: #efeded;
}
.boxing-prep-wrapper .title {
      font-size: 25px;
      font-weight: 600;
      padding: 10px;
      color: darkslategray;
}
.boxing-prep-wrapper .sub-title {
      display: flex;
      font-size: 25px;
      font-weight: 600;
      padding: 10px;
      color: darkslategray;
}

.chevron {
      font-size: 50px;
}
.chevron-border{

      border: 1px solid lightslategray;
      height: 53px;
      background-color: #c1bebe;
      border-radius: 15px;
      display: flex;
      text-align: center;
      vertical-align: middle;
  
}

.ticket-tally{
      color: white;
      background-color: red;
      font-size: 71px;
      align-self: center;
      font-weight: 500;
      padding: 5px;
}
.frame-category-tally {
display: flex;
}

.frame-details .ticket {

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
}

.frame-details .ticket .ticket-info {

      text-align: left;
}

.frame-details .ticket .shipstion-logo {
      height: 30px;
}

.order-shipment-status {
      font-size: 1.3rem;
      color: green;
      display: flex;
      padding: 10px;
}

.frame-modal-footerwrapper{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
}

.p-dialog-content {
      background-color: #e5e5e5;
}

.batch-info {
      display: flex;
      background-color: cornflowerblue;
      padding: 2px;
      color: white;
      font-variant: small-caps;
      justify-content: space-between;
}

.p-dialog .p-dialog-header .p-dialog-title {
      font-weight: 700;
      font-size: 2.25rem;
}

.p-dialog .frame {
      font-size: 1.5rem;
}

.p-card .p-card-title {
      font-size: 2.25rem;
}
.p-dialog  .serial {

      font-size: 1.5rem !important;
}

.p-timeline-event-opposite .text-xl{
      font-size: 1.5rem !important;
}
.p-card-subtitle{
      font-size: 1.5rem;
}

.frame.priority-low.ticket-at-station-over-an-hour  {
	-webkit-animation: pulse-low-priority 1400ms infinite alternate;
	        animation: pulse-low-priority 1400ms infinite alternate;
}

.frame.priority-high.ticket-at-station-over-an-hour  {
	-webkit-animation: pulse-high-priority 1400ms infinite alternate;
	        animation: pulse-high-priority 1400ms infinite alternate;
}
@-webkit-keyframes pulse-low-priority {
	0% { background-color: blue; }
	100% { background-color: #7171ef; }
}
@keyframes pulse-low-priority {
	0% { background-color: blue; }
	100% { background-color: #7171ef }
}

@-webkit-keyframes pulse-high-priority {
	0% { background-color: red; }
	100% { background-color: #f39c9c; }
}
@keyframes pulse-high-priority {
	0% { background-color: red; }
	100% { background-color: #f39c9c }
}

.pending-tickets-banner .pending-tickets-text {
      font-size: 65px;
      background-color: red;
      display: flex;
      justify-content: center;
      color: white;
}